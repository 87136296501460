<template>
  <div class="ForgetPassword">
    <div class="login-comp">
      <div class="login-comp-center comp-m">
        <div class="spin-nested-loading">
          <div class="spin-login">
            <div class="game-list">
              <div class="loginForm">
                <div class="title">
                  <span>重置密码</span>
                </div>
                <el-form
                  ref="ForgetPassword"
                  label-position="left"
                  style="width: 60%; margin: 0 auto"
                  :model="ForgetPassword"
                  :rules="ForgetPasswordRules"
                  label-width="100px"
                >
                  <el-form-item label="手机号" prop="phone_num">
                    <el-input
                      @keyup.enter.native="ClickLogin"
                      v-model="ForgetPassword.phone_num"
                      placeholder="请输入手机号码"
                    ></el-input>
                  </el-form-item>
                  <el-row>
                    <el-col :span="18">
                      <el-form-item label="短信验证码" prop="verification_code">
                        <el-input
                          v-model="ForgetPassword.verification_code"
                          placeholder="请输入短信验证码"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" style="height: 40px">
                      <el-button
                        @click="GetPhoneVerification"
                        :disabled="!disabledCodeBtn"
                        type="success"
                        >{{ codeText }}</el-button
                      >
                    </el-col>
                  </el-row>
                  <el-form-item label="新密码" prop="password">
                    <el-input
                      type="password"
                      show-password
                      @keyup.enter.native="ClickLogin"
                      v-model="ForgetPassword.password"
                      placeholder="请输入新密码"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <el-row style="width: 200px; margin: 0px auto">
                  <el-col
                    :span="24"
                    style="text-align: center; margin-top: 20px"
                  >
                    <el-button
                      type="primary"
                      style="width: 100%"
                      @click="ClickRegister"
                      plain
                      >修改密码</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { send_verification_code, retrieve_password } from "../api/login";
export default {
  data() {
    return {
      ForgetPassword: {},
      disabledCodeBtn: true,
      codeText: "获取验证码",
      ForgetPasswordRules: {
        phone_num: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
        ],
        verification_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
        ],
      },
    };
  },
  created() {
	  this.ForgetPassword ={}
  },
  watch:{
	  $route(to,form){
		  if(to.fullPath == '/ForgetPassword'){
			  this.ForgetPassword ={}
		  }
	  }
  },
  methods: {
    GetPhoneVerification() {
      if (!this.ForgetPassword.phone_num) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      } else {
        this.$store.commit("app/CHANGE_LOADING", true);
        send_verification_code({
          phone_num: this.ForgetPassword.phone_num,
        })
          .then((res) => {
            if (res.code == 200) {
              this.countDown(60);
              this.$store.commit("app/CHANGE_LOADING", false);
              this.$message({
                type: "success",
                message: "已发送短信",
              });
            } else {
              this.$store.commit("app/CHANGE_LOADING", false);
            }
          })
          .catch(() => {
            this.$store.commit("app/CHANGE_LOADING", false);
          });
      }
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.codeText = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.codeText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    ClickRegister() {
      this.$store.commit("app/CHANGE_LOADING", true);
      this.$refs["ForgetPassword"].validate((valid) => {
        if (valid) {
          retrieve_password(this.ForgetPassword)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$store.commit("app/CHANGE_LOADING", false);
                this.$router.push("/login");
                this.ForgetPassword = {};
              } else {
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(() => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        } else {
          this.$store.commit("app/CHANGE_LOADING", false);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ForgetPassword {
  height: auto;
  .login-comp {
    position: relative;
    height: auto;
    padding-top: 40px;
    width: 100%;
    min-width: 992px;
    background-color: rgb(245, 250, 252);
    .login-comp-center {
      margin: 0 auto;
      .spin-nested-loading {
        position: relative;
        .spin-login {
          position: relative;
          .game-list {
            min-height: 716px;
            position: relative;
            .loginForm {
              // position: absolute;
              // top: 50%;
              // left: 50%;
              margin: 0 auto;
              width: 800px;
              min-width: 800px;
              // height: 400px;
              // height: 150px;
              cursor: pointer;
              background-color: rgba(43, 103, 238, 0.1);
              padding: 40px 0;
              .title {
                text-align: center;
                width: 100%;
                height: 66px;
                line-height: 66px;
                font-size: 24px;
                color: var(--theme-title-color);
                // font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 1200px;
  }
}
@media (min-width: 992px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 100%;
  }
}
</style>